@font-face {
    font-family: 'NanumSquareRound';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

:root {
    font-family: 'NanumSquareRound', sans-serif;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: 'NanumSquareRound', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}
