.header {
    width: 100%;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
}

.logo {
    padding: 18px 0 0 30px;
}

.nav {
    display: flex;
}

.right {
    display: flex;
}

.img {
    width: 43px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register {
    width: 65px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    width: 76px;
    height: 80px;
}

.ticket {
    height: 54px;
    background: #f14766;
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding-top: 26px;
}

.lang {
    background: #494947;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.lang img {
    width: 9px;
    height: 5px;
    margin-top: 3px;
}

.navContent {
    height: 80px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navContent span {
    display: block;
    padding-top: 29px;
    margin: 0 20px;
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    font-family: 'nanumsquare';
}

.navEffect {
    width: 100%;
    background-color: #ed1940;
}

.menu {
    position: absolute;
    width: 100%;
    margin-top: 80px;
    background-color: white;
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.4);
    z-index: 10;
    overflow: hidden;
    display: flex;
}

.list {
    height: 100%;

    border-right: 1px solid #eeeeee;
    transition: padding 0.23s;
}

.list a {
    display: block;
    padding: 0 15px;
    font-size: 15px;
    color: #000000;
    white-space: nowrap;
    margin: 0 0 15px 0;
}
