.slide {
    width: 100%;
    height: 929px;
    display: flex;
}

.background {
    width: 100%;
    height: 929px;
    position: absolute;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    transition: background 1s;
}

.title {
    position: absolute;
    left: 50%;
    margin-left: -600px;
    top: 220px;
    width: 479px;
    height: 351px;
    transition: background 1s;
}

.title img {
    position: absolute;
    transition: opacity 1s;
    user-select: none;
    pointer-events: none;
}

.control {
    position: absolute;
    left: 50%;
    top: 580px;
    margin-left: -585px;
    display: flex;
}

.control span {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 10px;
    transition: background-color 0.5s;
    cursor: pointer;
}

.control div {
    display: block;
    width: 15px;
    height: 15px;
}

.selected {
    background-color: #ed1940 !important;
}

.arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    border: none;
    width: 47px;
    height: 50px;
    text-indent: -999em;
    z-index: 10;
    cursor: pointer;
}
