.list {
    width: 1200px;
    height: 550px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 350px;
}

.section {
    width: 360px;
    height: 550px;
    background-position: center;
    box-shadow: 0 0 15px 5px rgb(0 0 0 / 15%);
    background-size: 100%;
    transition: background-size 2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section:hover {
    background-size: 110%;
}

.title {
    width: 100%;
    text-align: center;
    padding-top: 60px;
}
.title img {
    margin-bottom: 15px;
}
.title p {
    margin: 0;
    font-size: 28px;
    font-weight: bolder;
}

.bottom {
    width: 100%;
    height: 221px;
    background-position: bottom;
    background-repeat: no-repeat;
    color: white;
}

.content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 160px;
}
.content span {
    display: flex;
    align-items: center;
}
.content img {
    margin-right: 4px;
}

.reserve {
    padding-right: 25px;
    border-right: solid 1px rgba(255, 255, 255, 0.49);;
    margin-right: 25px;
}
.reserve img {
    margin-right: 9px;
}
